import React from 'react';

import { useAuth } from '../Auth/Auth';
import { useQuery } from '@apollo/client';

import { useHistory, useParams } from 'react-router-dom';

import { loader } from 'graphql.macro';

import FilterMenu from '../Filters/FilterMenu';

const USERS_QUERY = loader('./list-users.graphql');

const UserMenu = () => {
  const { loading, error, data } = useQuery(USERS_QUERY);
  const { user } = useAuth();

  const history = useHistory();
  const {userId, categoryId} = useParams();

  const onUserSelected = (newId) => {
    if (newId && newId !== -1){
      if (categoryId) {
        history.push(`/items/u/${newId}/c/${categoryId}`)
      } else
        history.push(`/items/u/${newId}`)
    }
    else {
      if (categoryId) {
        history.push(`/items/c/${categoryId}`)
      } else
        history.push(`/items`)
    }
  }

  let result = data;
  if (loading || error) {
    result = { users: [] };
  }


  const sorter = (u1, u2) => {
    if (u1.source_id === user.uid) {
      return -1;
    } else if (u2.source_id === user.uid)  {
      return 1;
    } else if (u1.name < u2.name){
      return -1;
    }
    return 1;
  };


  const itemNamer = (item) => {
    if (item) {
      return item.source_id === user.uid ? 'You' : item.name;
    } else {
      return 'Everyone';
    }};
  const menuNamer = (item) => `Users: ${itemNamer(item)}`

  const valueExtractor = (item) => item ? item.source_id : -1;

  return (
    <FilterMenu
      filter={userId}
      onFilter={onUserSelected}
      sorter={sorter}
      menuNamer={menuNamer}
      itemNamer={itemNamer}
      valueExtractor={valueExtractor}
      data={result.users}
    />
  );
};

export default UserMenu;
