import firebase from "firebase/app";
import "firebase/storage";

const storageRef = firebase.storage().ref();



const uploadWithProgress = ({
  blob,
  user,
  onProgress,
  onDone,
  onError,
}) => {

  const fileName = `${Date.now()}`;
  const path = `${user.uid}/${fileName}`;
  const task = storageRef.child(
    `images/${path}.jpg`,
  ).putString(blob, 'data_url', { contentType: 'image/jpeg' });

  task.on(
    firebase.storage.TaskEvent.STATE_CHANGED,
    snap => {
      const progress = (snap.bytesTransferred / snap.totalBytes) * 100;
      if (typeof onProgress === 'function') {
        onProgress(progress);
      }
    },
    err => {
      console.log(err);
      if (typeof onError === 'function') {
        onError(err);
      }
    },
    () => {
      if (typeof onDone === 'function') {
        task.snapshot.ref.getDownloadURL().then(
          url => onDone(path, url),
        );
      }
    },
  );

  return path;
};

export default uploadWithProgress;
