import React from 'react';
import { theme, ThemeProvider, CSSReset } from '@chakra-ui/core';

const breakpoints = ["360px", "768px", "1024px", "1440px"];
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

const customTheme = {
    ...theme,
    breakpoints,
    colors: {
      ...theme.colors,
      white: '#fefbf6',
      black: '#1e181a',
      yellowish: {
        50: '#ffffda',
        100: '#fffbad',
        200: '#fff97d',
        300: '#fff74b',
        400: '#fff41a',
        500: '#e6db00',
        600: '#b3aa00',
        700: '#807900',
        800: '#4d4900',
        900: '#1b1800',
      },
      whitish: {
        50: '#fcf5e8',
        100: '#f5e0bf',
        200: '#efcd94',
        300: '#eab968',
        400: '#e5a43e',
        500: '#cc8b28',
        600: '#9e6c20',
        700: '#714d17',
        800: '#442e0e',
        900: '#170f03',
      },
      primary: {
        50: '#ddf5ff',
        100: '#aedfff',
        200: '#7ec9ff',
        300: '#4db3fe',
        400: '#229dfd',
        500: '#0e84e3',
        600: '#0167b2',
        700: '#004980',
        800: '#002c4f',
        900: '#00101f',
        },
        accent: {
           50: '#ffe3e5',
           100: '#feb6b9',
           200: '#f8888c',
           300: '#f35960',
           400: '#ee2a33',
           500: '#d51119',
           600: '#a60a13',
           700: '#77050d',
           800: '#4a0205',
           900: '#200000',
        },
    },
}

const Themed = ({ children }) => (
   <ThemeProvider theme={customTheme}>
     <CSSReset />
     {children}
    </ThemeProvider>
);

export default Themed;
