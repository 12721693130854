import React from 'react';

import {
  Stack,
} from '@chakra-ui/core';

import Team from './Team';

const TeamList = ({teams, ...props}) => {
  const sorted = teams ? [...teams] : [];
  sorted.sort((t1, t2) => t1.name < t2.name ? -1 : 1);
  return (
    <Stack spacing={1} w="100%">
      {sorted.map(t => (
        <Stack key={t.id || t.name} spacing={1} w="100%" direction="row">
          <Team team={t} {...props} />
        </Stack>
      ))}
    </Stack>
  );
};

export default TeamList;
