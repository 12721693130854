
import React from 'react';

import {
  Box,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel
} from "@chakra-ui/core";


const Section = ({title, tabs}) => {

  return (
    <Box
      my="0"
      borderWidth="1px"
      p="1rem"
    >
      <Heading
        as="h4"
        fontSize={{sm: "1rem", md: "1.5rem"}}
        textAlign="center"
        py={{sm: "0.618rem"}}
      >
        {title}
      </Heading>
      <Tabs
        align="center"
        variant="soft-rounded"
      >
        <TabList pb="1rem">
          {tabs.map(l => (
            <Tab key={l.title}>{l.title}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {tabs.map(l => (
            <TabPanel key={l.title}>
              <l.component />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Section;
