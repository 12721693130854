import React from 'react';

import { MdEdit } from 'react-icons/md'

import {
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  Image,
  List,
  ListItem,
} from '@chakra-ui/core';

import Graceful from 'react-graceful-image';

import { imageUrls } from '../../utils/urls';
import { useAuth } from '../Auth/Auth';

const title = str => str.toLowerCase().replace(
  /(^|\s)\S/g, t => t.toUpperCase()
) ;

const ItemCard = ({item, onEdit}) => {
  const urls = imageUrls(item.url);
  const hints = item.name.split(',').map(title);
  const { user } = useAuth();
  const itemFormatted = {
    imageUrl: urls.s200,
    hints,
    author: item.author.name,
    category: item.category.name,
    imageAlt: item.name,
  };

  return (
    <Grid
      templateColumns={{ sm: "200px 200px", md: "200px", lg: "200px" }}
      spacing={0}
      mx="auto"
      borderWidth="1px"
      rounded="lg"
      position="relative"
      bg="white"
    >
      <Image
        as={Graceful}
        objectFit="contain"
        maxW="200px"
        maxH="200px"
        src={itemFormatted.imageUrl}
        srcSet={urls.original}
        alt={itemFormatted.imageAlt}
      />
      <Box px="6" pt="6">
        <Box
          color="gray.500"
          fontWeight="semibold"
          letterSpacing="wide"
          fontSize="xs"
          textTransform="capitalize"
          ml="2"
        >
          {itemFormatted.author}
        </Box>
        <Divider />
        <Badge mt="1rem" rounded="full" px="2" variantColor="accent">
          {itemFormatted.category}
        </Badge>
        <Box my="1rem" px="1rem" fontSize="0.9rem">
          <List styleType="disc">
            {hints.map(h => (
              <ListItem listStylePosition="outside" key={h}>{h}</ListItem>
            ))};
          </List>
        </Box>
        {user.uid === item.author_id && (
          <Box position="absolute" right="0" bottom="0">
            <Button
              leftIcon={MdEdit}
              variant="ghost"
              onClick={() => onEdit(item)}
              fontSize={{sm: "0.8rem", md: "1rem"}}
            >
              Edit
            </Button>
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default ItemCard;
