import React from 'react';

import {
  Box,
  Flex,
} from '@chakra-ui/core';

const OverlayBox = ({
  showOverlay,
  Overlay,
  children,
  ...props
}) => {

  const OverlayChild = () => (
    <Overlay />
  );

  return (
    <Box position="relative" {...props}>
      {showOverlay && (
        <>
        <Flex
          zIndex={5}
          position="absolute"
          h="100%"
          w="100%"
          top={0}
          left={0}
          padding={0}
          justifyContent="center"
          alignItems="center"
        >
          <OverlayChild />
        </Flex>
        <Box
          zIndex={4}
          bg="accent.50"
          opacity="0.5"
          color="white"
          position="absolute"
          h="100%"
          w="100%"
          top={0}
          left={0}
          padding={0}
          transition={{opacity: "0.5s"}}
        />
        </>
      )}
      {children}
    </Box>
  );
};

export default OverlayBox;
