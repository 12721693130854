import React from 'react';

import { useQuery } from '@apollo/client';

import {
  Grid,
  Spinner,
} from '@chakra-ui/core';


import ItemCard from '../ItemCard/ItemCard';
import { loader } from 'graphql.macro';
const ITEMS_QUERY = loader('./list-items.graphql');


const ItemList = ({userId, categoryId, onEdit}) => {
  const cat = categoryId;
  const aut = userId;
  const { loading, error, data } = useQuery(ITEMS_QUERY, {
    variables: {
      byCategory: cat,
      byAuthor: aut,
    }
  });

  return (
    <Grid
      templateColumns={{sm: "100%", md: "1fr 1fr 1fr", lg: "1fr 1fr 1fr 1fr"}}
      gap="1rem"
      my="1rem"
      mx={{sm: 0, md: "1rem"}}
    >
    {loading || error ? (<Spinner />) : null}
    {!(loading || error) && (
      data.items.map(i => (<ItemCard key={i.id} item={i} onEdit={onEdit} />))
    )}
    </Grid>
  );

};

export default ItemList;
