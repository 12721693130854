import formatDistance from 'date-fns/formatDistance';
import formatRelative from 'date-fns/formatRelative';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isFuture from 'date-fns/isFuture';
import parseISO from 'date-fns/parseISO';

const smartDate = (date, prefixer, suffixer) => {
  const now = new Date();
  const interval = Math.abs(differenceInCalendarDays(date, now));

  let prefix = '';
  let suffix = '';
  const isRelative = interval < 6;

  if (prefixer) {
    prefix = prefixer(isRelative);
  }

  if (suffixer) {
    suffix = suffixer(isRelative);
  }

  let formatted;
  if (isRelative) {
    formatted = formatRelative(date, now);
  } else {
    formatted = formatDistance(date, now);
  }

  return `${prefix}${formatted}${suffix}`;
}


const smartDateString = (dateIn, prefix, nullString) => {
  if (!dateIn) return nullString;

  let date = dateIn;
  if (typeof dateIn === 'string') {
    date = parseISO(date);
  }
  const inFuture = isFuture(date);
  const prefixer = (isRelative) => {
    if (inFuture && isRelative) {
      return `${prefix}s `;
    } else if (inFuture && !isRelative) {
      return `${prefix}s in `;
    } else {
      return `${prefix}ed `;
    }
  }
  const suffixer = (isRelative) => {
    if (!inFuture && !isRelative) {
      return ' ago';
    } else {
      return '';
    }
  }
  return smartDate(date, prefixer, suffixer);
}

const smartStartDate = (date, nullString) => smartDateString(date, 'Start', nullString);
const smartEndDate = (date, nullString) => smartDateString(date, 'End', nullString);

export {
  smartStartDate,
  smartEndDate,
  smartDateString,
  smartDate,
};
