const HASURA_GRAPHQL_ENGINE_HOSTNAME = 'ideamap-api.thesolversclub.com';

const scheme = proto => {
  return window.location.protocol === 'https:' ? `${proto}s` : proto;
};

export const GRAPHQL_URL = `${scheme(
  'http'
)}://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`;
export const REALTIME_GRAPHQL_URL = `${scheme(
  'ws'
)}://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`;

export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyD1mrZEUd-NvM3JQJtg3wg3BRRhM7Ylq3A',
  authDomain: 'ideamap.thesolversclub.com',
  databaseURL: 'https://tsc-ideamap.firebaseio.com',
  projectId: 'tsc-ideamap',
  storageBucket: 'tsc-ideamap.appspot.com',
  messagingSenderId: '912437064710',
  appId: '1:912437064710:web:427bc032c0d2e66f623f87'
};

export const MS_TENANT_ID = 'abfcf2fa-024f-4a99-b7e0-ee4104a0e9ca';
