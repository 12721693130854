import React, { useState, useEffect, useContext } from "react";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

import { FIREBASE_CONFIG, MS_TENANT_ID } from '../../utils/constants'

export const AuthContext = React.createContext();
export const useAuth = () => useContext(AuthContext);

const provider = new firebase.auth.OAuthProvider('microsoft.com');
provider.setCustomParameters({ tenant: MS_TENANT_ID });
provider.addScope('email');
provider.addScope('profile');

firebase.initializeApp(FIREBASE_CONFIG);

export const AuthProvider = ({ children }) => {

  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    return firebase.auth().onAuthStateChanged(async user => {

      setLoading(false);
      if (user) {
        const token = await user.getIdToken();
        const idTokenResult = await user.getIdTokenResult();
        const hasuraClaim = idTokenResult.claims["https://hasura.io/jwt/claims"];

        if (hasuraClaim) {
          setUser(user);
          setToken(token);
          setIsAuthenticated(true);
        } else {
          // Check if refresh is required.
          const metadataRef = firebase
                .database()
                .ref("metadata/" + user.uid + "/refreshTime");

          metadataRef.on("value", async (data) => {
            if(!data.exists) return
            // Force refresh to pick up the latest custom claims changes.
            const token = await user.getIdToken(true);
            setUser(user);
            setToken(token);
            setIsAuthenticated(true);
          });
        }
      } else {
        setIsAuthenticated(false);
      }
    });
  }, []);

  const login = async () => {
    try {
      await firebase.auth().signInWithRedirect(provider);
    } catch (error) {
      console.log(error);
    }
  };

  const logout = async () => {
    try {
      setLoading(true);
      await firebase.auth().signOut();
      setIsAuthenticated(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        token,
        loading,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
