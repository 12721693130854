import React from 'react';

import { useQuery } from '@apollo/client';

import { useHistory, useParams } from 'react-router-dom';

import { loader } from 'graphql.macro';

import FilterMenu from '../Filters/FilterMenu';

const CATEGORIES_QUERY = loader('./list-categories.graphql');

const CategoryMenu = () => {
  const { loading, error, data } = useQuery(CATEGORIES_QUERY);

  const history = useHistory();
  const {userId, categoryId} = useParams();

  const onCategorySelected = (newId) => {
    if (newId && newId !== -1){
      if (userId) {
        history.push(`/items/u/${userId}/c/${newId}`)
      } else
        history.push(`/items/c/${newId}`)
    }
    else {
      if (userId) {
        history.push(`/items/u/${userId}`)
      } else
        history.push(`/items`)
    }
  }

  let result = data;
  if (loading || error) {
    result = { categories: [] };
  }


  const sorter = (c1, c2) => {
    if (c1.name < c2.name){
      return -1;
    }
    return 1;
  };

  const itemNamer = (item) => item ? item.name : 'All';

  const valueExtractor = (item) => item ? item.id : -1;
  const menuNamer = (item) => `Categories: ${itemNamer(item)}`

  return (
    <FilterMenu
      filter={categoryId}
      onFilter={onCategorySelected}
      sorter={sorter}
      menuNamer={menuNamer}
      itemNamer={itemNamer}
      valueExtractor={valueExtractor}
      data={result.categories}
    />
  );
};

export default CategoryMenu;
