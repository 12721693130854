import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { MdAdd } from 'react-icons/md'

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
} from '@chakra-ui/core';


import { useAuth } from '../Auth/Auth';
import Teams from './Team/Teams';

const GAME_INSERT = loader('./game-insert.graphql');
const GAME_UPDATE = loader('./game-update.graphql');


const GameEditor = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {

  const now = new Date().toISOString().slice(0, -14);
  const tomorrow = new Date();
  tomorrow.setDate(new Date().getDate() + 1);
  const minStart = now;
  const minEnd = startDate || tomorrow.toISOString().slice(0, -14);

  return (
    <SimpleGrid
      columns={2}
      minChildWidth="48%"
      spacing="16px"
    >
      <FormControl isRequired>
        <FormLabel htmlFor="start-date">
          Start Date
        </FormLabel>
        <Input
          variant="filled"
          type="date"
          min={minStart}
          id="start-date"
          placeholder="MM/DD/YY"
          value={startDate ? startDate : minStart}
          onChange={e => setStartDate(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="end-date">
          End Date
        </FormLabel>
        <Input
          variant="filled"
          type="date"
          id="end-date"
          min={minEnd}
          placeholder="MM/DD/YY"
          value={endDate ? endDate : ''}
          onChange={e => setEndDate(e.target.value)}
        />
      </FormControl>
    </SimpleGrid>
  );
}


const GameEditorModal = ({game, isOpen, onClose}) => {
  const { user  } = useAuth();
  const [startDate, setStartDate] = useState(game ? game.startDate : null);
  const [endDate, setEndDate] = useState(game ? game.endDate : null);
  const [teams, setTeams] = useState(game ? game.teams : []);

  const [gameInsert] = useMutation(
    GAME_INSERT,
    {
      refetchQueries: ['ListOnGoingGames', 'ListUpcomingGames'],
    }
  );
  const [gameUpdate] = useMutation(
    GAME_UPDATE,
    {
      refetchQueries: ['ListOnGoingGames', 'ListUpcomingGames', 'GetGame'],
    }
  );

  const gameEdited = () => {

    if (!startDate)  {
      return;
    }

    if (!teams || teams.length < 2) {
      return;
    }

    const insertGame = ({user, game, teams}) => {
      const teamData = teams.map(t => {
        const players = t.players.map(p => ({user_id: p.source_id}));
        return {
          name: t.name,
          players: {
            data: players,
          }
        };
      });
      return gameInsert({
        variables: {
          userId: user.uid,
          startDate,
          endDate,
          teams: {
            data: teamData,
          },
        },
      });
    };

    const updateGame = ({game}) => {
      return gameUpdate({
        variables: {
          id: game.id,
          startDate,
          endDate,
        },
      });
    };

    const mutateGame = ({game, teams}) => {
      let result;
      if (game.id) {
        result = updateGame({user, game});
      } else {
        result = insertGame({user, game, teams});
      }
      return result;
    };

    mutateGame({
      game: {
        startDate,
        endDate,
      },
      teams,
    });
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{game ? "Edit game" : "New game"}</ModalHeader>
        <ModalBody>
          <Stack>
            <GameEditor
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
            <Teams
              teams={teams}
              setTeams={setTeams}
            />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            leftIcon={MdAdd}
            variantColor="primary"
            onClick={() => gameEdited()}
            width="100%"
          >
            {game ? "Save game" : "Add game" }
          </Button>
          <Button
            onClick={onClose}
            variant="ghost">
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default GameEditorModal;
