import React from 'react'

import {
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Stack,
} from '@chakra-ui/core';



const SlidingNumber = ({
  defaultValue,
  value,
  min,
  max,
  step,
  onChange,
  sliderProps,
  numberProps,
  containerProps
}) => {

  return (
    <Stack
      justifyContent="space-between"
      direction="row"
      {...containerProps}
    >
      <Slider
        width="70%"
        defaultValue={defaultValue}
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={onChange}
        {...sliderProps}
      >
        <SliderTrack />
        <SliderFilledTrack />
        <SliderThumb />
      </Slider>
      <NumberInput
        width="25%"
        defaultValue={defaultValue}
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={onChange}
        keepWithinRange
        {...numberProps}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </Stack>
  );
}

export default SlidingNumber;
