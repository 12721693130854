import React, { useState } from 'react';
import { MdAdd,  MdShuffle } from 'react-icons/md'

import {
  Grid,
  Heading,
  IconButton,
  Stack,
  useDisclosure,
} from '@chakra-ui/core';


import TeamList from './TeamList';
import TeamEditor from './TeamEditor';
import Randomizer from './Randomizer';


const Teams = ({game, teams, setTeams}) => {

  const {isOpen, onOpen, onClose} = useDisclosure();
  const {
    isOpen: randomizeIsOpen,
    onOpen: randomizeOnOpen,
    onClose:randomizeOnClose,
  } = useDisclosure();
  const [editingTeam, setEditingTeam] = useState(null);

  const teamEdited = ({name, players}) => {
    if (editingTeam) {
      editingTeam.name = name;
      editingTeam.players = [...players];
    } else {
      const newTeams = [...teams];
      const team = {name, players};
      newTeams.push(team);
      setTeams(newTeams);
    }
    setEditingTeam(null);
    onClose();
  };

  const saveTeams = (teams) => {
    setTeams(teams);
    randomizeOnClose();
  };

  const editTeam = (team) => {
    if (!game) {
      setEditingTeam(team);
      onOpen();
    }
  };


  const deleteTeam = (team) => {
    if (!game) {
      const newTeams = teams.filter(t => t.name !== team.name);
      setTeams(newTeams);
    }
  };

  return (
    <Stack my="1rem">
      <Grid gap={3} mr="1rem" templateColumns="auto 32px 32px">
        <Heading as="h4" fontSize="1rem" mt="1rem">
          Teams
        </Heading>
        {!game && (
        <IconButton
          icon={MdShuffle}
          variantColor="primary"
          variant="outline"
          onClick={randomizeOnOpen}
          width="100%"
        />
        )}
        <IconButton
          icon={MdAdd}
          variantColor="primary"
          variant="outline"
          onClick={onOpen}
          width="100%"
        />
      </Grid>
      <TeamList
        noEdit={!!game}
        teams={teams}
        onEdit={editTeam}
        onDelete={deleteTeam}
      />
      <Randomizer
        isOpen={randomizeIsOpen}
        onClose={randomizeOnClose}
        onSave={saveTeams}
      />
      <TeamEditor
        isOpen={isOpen}
        onClose={onClose}
        team={editingTeam}
        teamEdited={teamEdited}
      />
    </Stack>
  );
};

export default Teams;
