import React, { useState } from 'react';

import {
  Stack,
  useDisclosure,
} from '@chakra-ui/core';

import { useParams } from 'react-router-dom';


import ItemList from '../components/ItemList/ItemList';
import ItemPageHeader from '../components/ItemList/ItemPageHeader';
import ItemEditModal from '../components/ItemCard/ItemEditModal';

const ItemsPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editItem, setEditItem] = useState(null);

  const closeEdit = () => {
    setEditItem(null);
    onClose();
  };

  const openEditor =(item) => {
    setEditItem(item);
    onOpen();
  }

  const { userId, categoryId } = useParams();

  return (
    <Stack spacing={32}>
      <ItemEditModal
        isOpen={isOpen}
        onClose={closeEdit}
        item={editItem}
      />
      <ItemPageHeader
        userId={userId}
        categoryId={categoryId}
        openEditor={() => openEditor(null)}
      />
      <ItemList
        userId={userId}
        categoryId={categoryId}
        onEdit={(item) => openEditor(item)}
      />
    </Stack>
  );
};

export default ItemsPage;
