import React from 'react';

import { MdAdd } from 'react-icons/md'

import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
} from '@chakra-ui/core';



const GamesPageHeader = ({openEditor}) => {

  return (
    <Flex
      maxW="100%"
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      px={{sm: "0.5rem", md: "1rem"}}
      py="1rem"
      bg="black"
      variant="dark"
      color="white.50"
    >
      <Heading
        as="h2"
        align="center"
        color="white"
        textAlign={{sm: "center", md: "left"}}
        width={{sm: "full", md: "auto"}}
        fontSize={{sm: "1.1618rem", md: "1.618rem", lg: "2.1618rem"}}
        mb={{sm: "0.5rem", md: "0"}}
        mr={{sm: "0", md: "1rem"}}
      >
        Games
      </Heading>
      <Box
        display={{sm: "none", md: "flex"}}
        width={{sm: "full", md: "auto"}}
        alignItems="center"
        flexGrow={1}
      />
      <Grid
        templateColumns={{sm: "100%", md: "200px auto 100px"}}
        gap={3}
        mr={{sm: "0", md: "1rem"}}
        align="end"
        justify="end"
        flexGrow={1}
      >
        <Box/>
        <Box/>
        <Button
          leftIcon={MdAdd}
          variant="outline"
          color="white"
          _hover={{"color": "yellow"}}
          onClick={openEditor}
          maxW={{sm: "auto", md: "100px"}}
        >
          New
        </Button>
      </Grid>
    </Flex>
  );
};

export default GamesPageHeader;
