import React from 'react';

import {
  Grid,
  Heading,
  Stack,
} from "@chakra-ui/core";

import { useAuth } from '../components/Auth/Auth';

import DashboardSection from '../components/Dashboard/Section';
import DashItems from '../components/Dashboard/Items';
import DashPoints from '../components/Dashboard/Points';
import DashFairness from '../components/Dashboard/Fairness';
import DashOngoing from '../components/Dashboard/OngoingGames';
import DashUpcoming from '../components/Dashboard/UpcomingGames';
import DashCompleted from '../components/Dashboard/CompletedGames';

const Dashboard = () => {
  const { user } = useAuth();

  return (
    <Stack spacing={6}>
      <Heading
        as="h2"
        textDecoration="underline"
        fontWeight="light"
        align="center"
        color="black.50"
        textAlign={{sm: "center", md: "left"}}
        width={{sm: "full", md: "auto"}}
        fontSize={{sm: "1.1618rem", md: "1.618rem", lg: "2.1618rem"}}
        my={{sm: "1rem", md: "1.5rem"}}
        mx={{sm: "0.618rem", md: "2rem"}}
      >
        Hi {user.displayName}!
      </Heading>
      <Grid
        templateColumns={{sm: "100%", md: "1fr 1fr", xl: "1fr 1fr 1fr"}}
        gap="1rem"
        mx={{sm: 0, md: "1rem"}}
      >
        <DashboardSection
          title="Games"
          tabs={[
            { title: "Ongoing", component: DashOngoing },
            { title: "Upcoming", component: DashUpcoming },
            { title: "Completed", component: DashCompleted },
          ]}
        />
        <DashboardSection
          title="Items"
          tabs={[{ title: "Created", component: DashItems }]}
        />
        <DashboardSection
          title="Points"
          tabs={[
            { title: "Score", component: DashPoints },
            { title: "Fairness", component: DashFairness },
          ]}
        />
      </Grid>
    </Stack>
  );
};

export default Dashboard;
