import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/link-ws";
import { getMainDefinition } from "@apollo/client/utilities";

import { GRAPHQL_URL, REALTIME_GRAPHQL_URL } from "./utils/constants";

const getHeaders = (token) => {
  const headers = {};
  if (token) {
    headers.authorization = `Bearer ${token}`;
  }
  return headers;
};

const makeApolloClient = (token) => {
  if (!token || token === '') {
    return null;
  }
  // Create an http link:
  const httpLink = new HttpLink({
    uri: GRAPHQL_URL,
    fetch,
    headers: getHeaders(token)
  });

  // Create a WebSocket link:
  const wsLink = new WebSocketLink({
    uri: REALTIME_GRAPHQL_URL,
    options: {
      reconnect: true,
      timeout: 30000,
      connectionParams: () => {
        return {
          authToken: token,
          headers: getHeaders(token)
        };
      },
    }
  });

  // chose the link to use based on operation
  const link = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === "OperationDefinition" && operation === "subscription";
    },
    wsLink,
    httpLink
  );

  const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache({
      addTypename: true
    })
  });

  return client;
};

export default makeApolloClient;
