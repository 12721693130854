import React from 'react';

import { MdEdit, MdDelete } from 'react-icons/md'

import {
  Box,
  IconButton,
  Grid,
} from '@chakra-ui/core';

import { useAuth } from '../../Auth/Auth';


const Team = ({
  team,
  onEdit,
  onDelete,
  noEdit,
  game,
}) => {
  const { user } = useAuth();
  const userName = (p) => p.user ? p.user.name : p.name;
  const isMaster = game ? game.master.source_id === user.uid : true;
  const isPlayer = team.players.some(p => p.user.source_id === user.uid);
  const canEdit = !noEdit && (isMaster || isPlayer);
  const canDelete = !noEdit && isMaster;
  return (
    <Grid
      w="100%"
      templateColumns={noEdit ? "100px auto" : "100px auto 48px 48px"}
      borderWidth={1}
      pl="8px"
    >
      <Box mt="8px">{team.name}</Box>
      <Box mt="8px">{team.players.map(userName).join(', ')}</Box>
      {canEdit && (
        <IconButton
          variant="ghost"
          icon={MdEdit}
          onClick={() => onEdit(team)}
        />
      )}
      {canDelete && (
        <IconButton
          variant="ghost"
          icon={MdDelete}
          onClick={() => onDelete(team)}
        />
      )}
    </Grid>
  );
}


export default Team;
