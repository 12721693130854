import React from 'react';

import {
  Box,
  Divider,
  Grid,
  Text,
} from '@chakra-ui/core';

import { useAuth } from '../Auth/Auth';

const DashboardScore = ({data, scorer}) => {
  const { user } = useAuth();
  return (
    <Box>
      {data.map((u, i) => (
        <Box
          pt={i === 0 ? "0rem" : "1rem"}
          pb="0.5rem"
          key={u.id}
          bg={u.source_id === user.uid ? 'yellow.100' : 'transparent'}
        >
          {i > 0 && (<Divider mb="0rem" mt="-1rem" height="1px" />)}
          <Grid
            px={{sm: "0.5rem", md: "1rem"}}
            pt="1rem"
            templateColumns="auto 48px" gap={0}
          >
            <Text >{u.source_id === user.uid ? 'You' : u.name}</Text>
            <Text textAlign="right">{scorer(u) || 0}</Text>
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default DashboardScore;
