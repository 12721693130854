import React, { useEffect } from 'react';

import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";

import { ApolloProvider, gql } from '@apollo/client';
import makeApolloClient from './apollo';

import { useAuth } from './components/Auth/Auth';

import Topnav from './components/Chrome/Topnav';
import Footer from './components/Chrome/Footer';

import Dashboard from './pages/Dashboard';
import ItemsPage from './pages/ItemsPage';
import GamesPage from './pages/GamesPage';
import GamePage from './pages/GamePage';

let client;

const Inner = () => (
  <header>
    <Topnav />
  </header>
);


const updateLastSeen = (userId, client) => {
  if (!userId) {
    return;
  }
  const timestamp = new Date().toISOString();
  if (client) {
    client
      .mutate({
        mutation: gql`
        mutation updateLastSeen($userId: String!, $timestamp: timestamptz!) {
            update_users(
            where: { auth0_id: { _eq: $userId } }
            _set: { last_seen: $timestamp }
            ) {
            affected_rows
            }
        }
        `,
        variables: {
          userId: userId,
          timestamp: timestamp
        }
      })
      .then(() => {
        // handle response if required
      })
      .catch(error => {
        console.log('Error updating last seen');
        console.error(error);
      });
  }
};

const Routes = ({children}) => {
  return (
    <Router>
      {children}
      <Switch>
        <Route exact path="/">
          <Dashboard />
        </Route>
        <Route exact path="/items">
          <ItemsPage />
        </Route>
        <Route exact path="/items/u/:userId">
          <ItemsPage />
        </Route>
        <Route exact path="/items/c/:categoryId">
          <ItemsPage />
        </Route>
        <Route exact path="/items/u/:userId/c/:categoryId">
          <ItemsPage />
        </Route>
        <Route exact path="/games">
          <GamesPage />
        </Route>
        <Route exact path="/games/:gameId">
          <GamePage />
        </Route>
      </Switch>
    </Router>
  );
};

const App = () => {

  const { token, user, isAuthenticated, loading } = useAuth();

  useEffect(() => {
    updateLastSeen(user ? user.sub : null, client);
  });

  if (loading) {
    return <div>Loading...</div>;
  }
  if (isAuthenticated) {
    if (!client) {
      client = makeApolloClient(token);
    }
  }

  if (client) {
    return (
      <ApolloProvider client={client}>
        <div className="app-container">
          <Routes>
            <Inner />
          </Routes>
          <Footer />
        </div>
      </ApolloProvider>
    );
  } else {
    return (
      <div className="app-container">
        <Inner />
        <Footer />
      </div>
    );
  }
}

export default App;
