export const imageUrls = (path) => {

  const [author, file] = path.split('/');
  const prefix = "https://firebasestorage.googleapis.com/v0/b/tsc-ideamap.appspot.com/o/images%2F";
  const mid = suffix => suffix === '' ?  '' : '%2Fresized';
  const suffixes = ['', '_100x100', '_200x200', '_300x300', '_384x384'];
  const urls = ['original', 's100', 's200', 's300', 's384'];

  return urls.reduce((a, t, i) => {
    const suffix = suffixes[i];
    a[t] = `${prefix}${author}${mid(suffix)}%2F${file}${suffix}.jpg?alt=media`;
    return a;
  }, {});
};

