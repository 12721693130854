import React from "react";
import ReactDOM from "react-dom";

import 'focus-visible/dist/focus-visible';

import Themed from "./components/Theme/Theme.js"
import { AuthProvider } from "./components/Auth/Auth.js"



import * as serviceWorker from "./serviceWorker";
import App from "./App";


ReactDOM.render(
  <Themed>
    <AuthProvider>
      <App />
    </AuthProvider>
  </Themed>,
  document.getElementById("root")
);

serviceWorker.unregister();
