import React from 'react';

import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
} from '@chakra-ui/core';


import ItemEditor from '../ItemCard/ItemEditor';

const ItemEditModal = ({item, isOpen, onClose}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {item ? 'Edit Item' : 'Insert Item'}
        </ModalHeader>
        <ModalBody>
          <Box w="100%" position="relative">
            <ItemEditor item={item} finish={onClose} />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};


export default ItemEditModal;
