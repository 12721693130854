import React, { useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';

import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Image,
  Input,
  PseudoBox,
  Stack,
  useDisclosure,
} from '@chakra-ui/core';


import ImageSelect from './ImageSelect';
import CategorySelector from '../Category/CategorySelector';
import OverlayProgress from '../OverlayBox/OverlayProgress';

import { useAuth } from '../Auth/Auth';
import uploadWithProgress from '../../utils/upload';
import { imageUrls } from '../../utils/urls';

const ITEM_INSERT = loader('./item-insert.graphql');
const ITEM_UPDATE = loader('./item-update.graphql');

const ItemEditor = ({ item, finish  }) => {
  const hiddenInput = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const [categoryId, setCategoryId] = useState(null);
  const [hints, setHints] = useState(null);
  const { user  } = useAuth();
  const disc = useDisclosure();

  const def = "https://via.placeholder.com/300/EDF2F7/1A202C?text=Select+your+drawing";

  function readFile(file) {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  const selectImage = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      await setImageSrc(imageDataUrl);
      disc.onOpen();
    }
  };

  const imageClick = async (e) => {
    if (!imageSrc) {
      hiddenInput.current.click();
    } else {
      disc.onOpen();
    }
  }

  const [itemInsert] = useMutation(
    ITEM_INSERT,
    {
      refetchQueries: ['Items'],
    }
  );
  const [itemUpdate] = useMutation(
    ITEM_UPDATE,
    {
      refetchQueries: ['Items'],
    }
  );

  const submitItem = () => {
    if (!categoryId || !hints)  {
      return;
    }

    if (!item && !croppedImage) {
      return;
    }

    const insertItem = ({user, item}) => {
      return itemInsert({
        variables: {
          userId: user.uid,
          categoryId: item.categoryId,
          name: item.name,
          url: item.url,
        },
      });
    };

    const updateItem = ({item}) => {
      return itemUpdate({
        variables: {
          id: item.id,
          categoryId: item.categoryId,
          name: item.name,
          url: item.url,
        },
      });
    };

    const mutateItem = ({item}) => {
      let result;
      if (item.id) {
        result = updateItem({user, item});
      } else {
        result = insertItem({user, item});
      }
      if (!croppedImage) {
        finish();
      }
      return result;
    };

    const done = (x) => {
      setShowProgress(false);
      finish();
    };

    let path = item ? item.url : null;
    if (croppedImage) {
      setShowProgress(true);
      path = uploadWithProgress({
        blob: croppedImage,
        user,
        onProgress: setProgress,
        onDone: done,
      });
    }
    mutateItem({
      user,
      item:{
        id: item ? item.id : null,
        categoryId,
        name: hints,
        url: path
      }});
  };

  const urls = item ? imageUrls(item.url) : {};
  const imageUrl = item ? urls.s200 : null;

  if (item) {
    if (!hints) {
      setHints(item.name);
    }

    if (!categoryId) {
      setCategoryId(item.category.id);
    }
  }

  return (
    <Box
      mx="auto"
      my="0"
      maxW="sm"
      position="relative"
    >
      <OverlayProgress
        showOverlay={showProgress}
        progress={progress}
      >
        <Flex
          align="center"
          justify="center"
          wrap="wrap"
          mx="auto"
          maxW="md"
          position="relative"
        >
          <ImageSelect
            {...disc}
            img={croppedImage ? croppedImage : imageSrc}
            setCroppedImage={setCroppedImage}
          />
          <Button
            position="absolute"
            top="-60px"
            onClick={() => hiddenInput.current.click()}
          >
            { !item ? 'Select Image' : 'Replace Image' }
          </Button>
          <input
            hidden
            type='file'
            ref={hiddenInput}
            onChange={selectImage}
          />
          <PseudoBox
            as="button"
            w="sm"
            my="0.5rem"
            _hover={{ boxShadow: "outline"  }}
          >
            <Image
              mx="auto"
              onClick={imageClick}
              src={croppedImage ? croppedImage : imageUrl}
              fallbackSrc={def}
              size="150px"
              alt="selection"
              objectFit="contain"
            />
          </PseudoBox>
          <Stack spacing={1} w="100%">
            <Box w="100%" borderWidth="1px" padding="1rem">
              <FormControl isRequired>
                <FormLabel htmlFor="category-selector">
                  Category
                </FormLabel>
                <CategorySelector
                  id="category-selector"
                  onChange={e => setCategoryId(e.target.value)}
                  selection={categoryId ? categoryId : ''}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="item-name">
                  Hints
                </FormLabel>
                <Input
                  variant="filled"
                  type="text"
                  id="item-name"
                  placeholder="hint 1, hint 2, hint 3"
                  value={hints ? hints : ''}
                  onChange={e => setHints(e.target.value)}
                />
                <FormHelperText id="item-name-helper-text">
                  Separate multiple hints with commas
                </FormHelperText>
              </FormControl>
            </Box>
            <ButtonGroup alignSelf="end">
              <Button
                variantColor="primary"
                mt={2}
                onClick={() => submitItem()}
              >
                { item ? 'Update Item' : 'Add Item' }
              </Button>
              <Button
                color="black"
                variantColor="ghost"
                mt={2}
                onClick={() => finish()}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </Stack>
        </Flex>
      </OverlayProgress>
    </Box>
  );
}

export default ItemEditor;
