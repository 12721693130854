import React from 'react';

import { useQuery } from '@apollo/client';

import { loader } from 'graphql.macro';

import DashboardScore from './Score';

const DASH_POINTS_QUERY = loader('./dash-points.graphql');


const scorer = (u) => u.scores_aggregate.aggregate.sum.points;

const DashboardPoints = () => {
  const { loading, error, data } = useQuery(DASH_POINTS_QUERY);

  let result = data;
  if (loading || error) {
    result = { users: [] };
  }

  return <DashboardScore data={result.users} scorer={scorer}/>;
};

export default DashboardPoints;
