import React, { useState } from 'react';

import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/core';

import { NavLink } from 'react-router-dom';

import { useAuth } from '../Auth/Auth';

const User = ({ user }) => {
  if (user) {
    const {
      displayName: name
    } = user;

    return (
      <>
        {name && (
          <Text
            fontSize={{sm: "0.8rem", md: "1rem"}}
            color="primary.50"
          >
            { name }
          </Text>
        )}
      </>
    );
  }

  return (<></>);

};

const Topnav = (props) => {
  const { user, isAuthenticated, login, logout } = useAuth();

  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);
  const Nav = ({exact, children, url}) => (
    <NavLink
      as={NavLink}
      exact={exact}
      className="nav-link"
      activeClassName="active nav-link-active"
      to={url}
    >
      {children}
    </NavLink>
  );

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      p={["0.5rem", "1rem"]}
      bg="white"
      color="black"
      maxW="100%"
      {...props}
    >
      <Flex align="center" mr={5}>
        <Image
          size={{sm: "32px", md: "64px", lg: "100px"}}
          objectFit="contain"
          src="/images/ideamap-logo.svg"
          alt="Idea map"
          mx={{sm: "0.618rem", md: "1rem"}}
        />
        <Stack>
          <Heading
            fontSize={{sm: "1.1618rem", md: "2.1618rem", lg: "3.1618rem"}}
            lineHeight={{sm: "1.1618rem", md: "2.1618rem", lg: "3.1618rem"}}
            as="h1"
            pb={{sm: "0rem", md: "0.618rem"}}
            mt={{sm: "0.1rem", md: "0.618rem"}}
          >
            Idea Map
          </Heading>
          <Heading
            fontSize={{sm: "0.618rem", md: "1.1618rem", lg: "2.1618rem"}}
            as="h2"
            pt={{sm: "0.1618rem", md: "0.618rem"}}
            mt={{sm: "-0.5rem", md: "-1.618rem"}}
          >
            Think. Create. Explain.
          </Heading>

        </Stack>
      </Flex>
      <Box display={{sm: "block", md: "none"}} onClick={handleToggle}>
        <svg
          fill="black"
          width="12px"
          height="12px"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </Box>
      <Box
        display={{sm: show ? "flex" : "none", md: "flex"}}
        width={{sm: "full", md: "auto"}}
        alignItems="center"
        flexGrow={1}
      />
      <Box
        display={{sm: show ? "block" : "none", md: "block"}}
        mt={{ base: 4, md: 0 }}
        mr={6}
      >
        {!isAuthenticated && (
          <Button
            variant="primary"
            bg="primary.400"
            color="white"
            onClick={() => login()}
          >
            Log in
          </Button>
        )}
        {isAuthenticated && (
          <Stack
            direction="row"
            mt={{sm: "0rem", md:"0.618rem", lg:"0rem"}}
          >
            <Nav exact url="/">Home</Nav>
            <Nav url="/items">Items</Nav>
            <Nav url="/games">Games</Nav>
            <Menu>
              <MenuButton
                variant="primary"
                bg="primary.400"
                color="white"
                as={Button}
                rightIcon="chevron-down"
              >
                <User user={user} />
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => logout()}>
                  Log out
                </MenuItem>
              </MenuList>
            </Menu>
          </Stack>
        )}
      </Box>
    </Flex>
  );
};

export default Topnav;
