import React, { useState } from 'react';
import { MdCheck,  MdShuffle } from 'react-icons/md'
import { useQuery } from '@apollo/client';

import { loader } from 'graphql.macro';

import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
} from '@chakra-ui/core';


import CheckBoxList from '../../CheckBoxList/CheckBoxList';
import TeamList from './TeamList';


const USERS_QUERY = loader('../../User/list-users.graphql');
const knuthShuffle = (arr) => {
  var rand, temp, i;

  for (i = arr.length - 1; i > 0; i -= 1) {
    //get random between zero and i (inclusive)
    rand = Math.floor((i + 1) * Math.random());
    //swap i and the zero-indexed number
    temp = arr[rand];
    arr[rand] = arr[i];
    arr[i] = temp;
  }
  return arr;
}

const Randomizer = ({isOpen, onClose, onSave}) => {
  const {loading, error, data} = useQuery(USERS_QUERY);
  const [teams, setTeams] = useState([]);
  const [selection, setSelection] = useState([]);

  if (loading || error) {
    return <Spinner />;
  }
  const randomize = (newSelection) => {
    if (newSelection) {
      setSelection(newSelection);
    }
    const workingSelection = newSelection || selection;

    if (!workingSelection || workingSelection.length === 0) {
      setTeams([]);
      return;
    }
    const shuffled = knuthShuffle(workingSelection);

    const sorted = [...teams];
    sorted.sort((t1, t2) => t1.name < t2.name ? -1 : 1);
    // split pairs
    const players = shuffled.reduce((acc, cv) => {
      const last = acc.pop();
      last.push(cv);
      const newAcc = [...acc, last];
      if (last.length === 2) {
        newAcc.push([]);
      }
      return newAcc;
    }, [[]]);

    const last = players.pop();
    if (last.length === 2) {
      players.push(last);
    } else if (last.length === 1) {
      const prev = players.pop();
      players.push([...prev, ...last]);
    }

    const makeName = (ps, i) => {
      return `${ps.map(p => p.name[0]).join('')}-${i + 1}`;

    }

    const newTeams = players.map((ps, i) => {
      const name = makeName(ps, i);
      return {
        name,
        players: ps,
      };
    });
    setTeams(newTeams);
  };

  const users = data.users;

  const GenerateButton = () => (
    <Button
      leftIcon={MdShuffle}
      variantColor="primary"
      variant="outline"
      onClick={() => randomize()}
      width="100%"
    >
      Generate new team
    </Button>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Generate Teams</ModalHeader>
        <ModalBody>
          <CheckBoxList
            objects={users}
            keyGetter={u => u.source_id}
            textGetter={u => u.name}
            onChange={randomize}
            ActionButton={GenerateButton}
          />
          <Stack spacing={1} w="100%">
            <TeamList noEdit teams={teams} />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            leftIcon={MdCheck}
            variantColor="primary"
            onClick={() => onSave(teams)}
            width="100%"
          >
            Use
          </Button>
          <Button
            onClick={onClose}
            variant="ghost">
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default Randomizer;
