import React from 'react';

import {
  Box,
  Flex,
  Heading,
} from '@chakra-ui/core';


import { smartStartDate, smartEndDate } from '../../utils/date';


const GamePageHeader = ({game}) => {

  return (
    <Flex
      maxW="100%"
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      px={{sm: "0.5rem", md: "1rem"}}
      py="1rem"
      bg="black"
      variant="dark"
      color="white.50"
    >
      <Heading
        as="h2"
        align="center"
        color="white"
        textAlign={{sm: "center", md: "left"}}
        width={{sm: "full", md: "auto"}}
        fontSize={{sm: "1.1618rem", md: "1.618rem", lg: "2.1618rem"}}
        mb={{sm: "0.5rem", md: "0"}}
        mr={{sm: "0", md: "1rem"}}
      >
        {smartStartDate(game.start_date)}
      </Heading>
      <Heading
        as="h2"
        align="center"
        color="white"
        textAlign={{sm: "center", md: "left"}}
        width={{sm: "full", md: "auto"}}
        fontSize={{sm: "1.1618rem", md: "1.618rem", lg: "2.1618rem"}}
        mb={{sm: "0.5rem", md: "0"}}
        mr={{sm: "0", md: "1rem"}}
      >
        {smartEndDate(game.end_date)}
      </Heading>
      <Box
        display={{sm: "none", md: "flex"}}
        width={{sm: "full", md: "auto"}}
        alignItems="center"
        flexGrow={1}
      />
    </Flex>
  );
};

export default GamePageHeader;
