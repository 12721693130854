import React from 'react';

import {
  Flex,
  CircularProgress,
  CircularProgressLabel,
} from '@chakra-ui/core';


import OverlayBox from './OverlayBox';

const ProgressOverlay = ({progress}) => {
  const progressValue = Math.floor(progress);
  return (
    <Flex>
      <CircularProgress
        value={progressValue}
        color="primary"
        trackColor="yellow"
        size="100px"
      >
        <CircularProgressLabel>{progressValue}%</CircularProgressLabel>
      </CircularProgress>
    </Flex>
  );
};

const OverlayProgress = ({
  progress,
  children,
  ...props
}) => {

  const overlay = () => (
    <ProgressOverlay progress={progress}/>
  );

  return (
    <OverlayBox Overlay={overlay} {...props}>
      {children}
    </OverlayBox>
  );
};

export default OverlayProgress;
