import React from 'react';

import Games from './Games'

import { useAuth } from '../Auth/Auth';
import { useQuery } from '@apollo/client';

import { loader } from 'graphql.macro';
import {formatDistance, parseISO} from 'date-fns';

const DASH_UPCOMING_GAMES_QUERY = loader('../Game/list-upcoming-games.graphql');


const UpcomingGames = () => {
  const { user } = useAuth();
  const now = new Date().toISOString().slice(0, -14);
  const dateFormatter = (date) => {
    return formatDistance(parseISO(date), new Date())
  };
  const { loading, error, data } = useQuery(
    DASH_UPCOMING_GAMES_QUERY, {
      variables: {
        userId: user.uid,
        masterId: user.uid,
        now,
      },
    },
  );

  let result = data;
  if (loading || error) {
    result = { games: [] };
  }

  return <Games
           games={result.games}
           dateFormatter={dateFormatter}
         />;
};

export default UpcomingGames;
