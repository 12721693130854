import React from 'react';

import { MdPageview } from 'react-icons/md'

import {
  Box,
  Button,
  Divider,
  Grid,
  Text,
} from '@chakra-ui/core';

import { Link as RRLink } from 'react-router-dom';

import { useAuth } from '../Auth/Auth';
import { useQuery } from '@apollo/client';

import { loader } from 'graphql.macro';

const DASH_ITEMS_QUERY = loader('./dash-items.graphql');

const DashboardItems = () => {
  const { user } = useAuth();
  const { loading, error, data } = useQuery(DASH_ITEMS_QUERY);

  let result = data;
  if (loading || error) {
    result = { users: [] };
  }

  return (
    result.users.map((u, i) => (
      <Box
        pt={i === 0 ? "0rem" : "1rem"}
        pb="0.5rem"
        key={u.id}
        bg={u.source_id === user.uid ? 'yellow.100' : 'transparent'}
      >
        {i > 0 && (<Divider mb="0rem" mt="-1rem" height="1px" />)}
        <Grid
          px={{sm: "0.5rem", md: "1rem"}}
          pt="1rem"
          templateColumns="auto 48px 96px" gap={1}
        >
          <Text >{u.source_id === user.uid ? 'You' : u.name}</Text>
          <Text textAlign="right" pr="1rem">
            {u.items_aggregate.aggregate.count}
          </Text>
          <Button
            height="2rem"
            mt="-0.3rem"
            variant="outline"
            leftIcon={MdPageview}
            as={RRLink}
            to={`/items/u/${u.source_id}`}
          >
            View
          </Button>
        </Grid>
      </Box>
    ))
  );
};

export default DashboardItems;
