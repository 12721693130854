import React, { useState } from 'react';
import { MdAdd } from 'react-icons/md'

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/core';


import UserSelector from '../../User/UserSelector';
import UserList from '../../User/UserList';

const TeamEditor = ({team, teamEdited, isOpen, onClose}) => {

  const [players, setPlayers] = useState(team ? team.players : null);
  const [player, setPlayer] = useState(null);
  const [name, setName] = useState(team ? team.name : null);

  const addPlayer = () => {
    const newPlayers = players ? [...players] : [];
    newPlayers.push(player);
    setPlayers(newPlayers);
    setPlayer(null);
  };

  const cleanSlate = () => {
    setPlayers(null);
    setPlayer(null);
    setName(null);
  }

  const onSave = () => {
    teamEdited({name, players, team});
    cleanSlate();
  }

  const onCancel = () => {
    cleanSlate();
    onClose();
  }

  const exclude = players ? players.map(p => p.sourceId) : [];

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{team ? "Edit team" : "New team"}</ModalHeader>
        <ModalBody>
          <Stack spacing={1} w="100%">
            <Stack spacing={1} w="100%" direction="row">
              <Box w="100%" padding="1rem">
                <FormControl isRequired>
                  <FormLabel htmlFor="team-name">
                    Name
                  </FormLabel>
                  <Input
                    variant="filled"
                    type="text"
                    id="team-name"
                    placeholder="The Avengers"
                    value={name ? name : ''}
                    onChange={e => setName(e.target.value)}
                  />
                </FormControl>
              </Box>
            </Stack>
            <Stack mb="1rem" spacing={1} w="100%" direction="row">
              <Box w="100%" padding="1rem">
                <FormControl isRequired>
                  <FormLabel htmlFor="player-selector">
                    Player
                  </FormLabel>
                  <Grid templateColumns="auto 140px"  columns={2}>
                    <UserSelector
                      id="player-selector"
                      onChange={setPlayer}
                      selection={player}
                      exclude={exclude}
                    />
                    <Button
                      leftIcon={MdAdd}
                      variantColor="primary"
                      ml={2}
                      onClick={() => addPlayer()}
                    >
                      Add Player
                    </Button>
                  </Grid>
                  <FormHelperText id="player-helper-text">
                    Ideal team size is 2. If there is an odd number of players,
                    3 players could work.
                  </FormHelperText>
                </FormControl>
              </Box>
            </Stack>
            <Box mx="1rem">
              <UserList users={players}/>
            </Box>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            leftIcon={MdAdd}
            variantColor="primary"
            onClick={onSave}
            width="100%"
          >
            {team ? "Save" : "Add" }
          </Button>
          <Button
            onClick={onClose}
            variant="ghost">
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}


export default TeamEditor;
