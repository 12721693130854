import React, { useState  } from 'react';

import {
  Heading,
  Stack,
  useDisclosure,
} from "@chakra-ui/core";

import GamesPageHeader from '../components/Game/GamesPageHeader';
import GameEditorModal from '../components/Game/GameEditorModal';

import OngoingGames from '../components/Dashboard/OngoingGames';
import UpcomingGames from '../components/Dashboard/UpcomingGames';
import CompletedGames from '../components/Dashboard/CompletedGames';

const GamesPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editGame, setEditGame] = useState(null);

  const closeEdit = () => {
    setEditGame(null);
    onClose();
  };

  const openEditor =(game) => {
    setEditGame(game);
    onOpen();
  }
  return (

    <Stack spacing={32}>
      <GameEditorModal
        isOpen={isOpen}
        onClose={closeEdit}
        game={editGame}
      />
      <GamesPageHeader
        openEditor={() => openEditor(null)}
      />
      <Stack spacing={8} px="1.5rem">
        <Stack spacing={4}>
          <Heading
            as="h4"
            fontSize="2rem"
            my="1rem"
          >
            Ongoing
          </Heading>
          <OngoingGames />
        </Stack>
        <Stack spacing={4}>
          <Heading
            as="h4"
            fontSize="2rem"
            my="1rem"
          >
            Upcoming
          </Heading>
          <UpcomingGames />
        </Stack>
        <Stack spacing={4}>
          <Heading
            as="h4"
            fontSize="2rem"
            my="1rem"
          >
            Completed
          </Heading>
          <CompletedGames />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default GamesPage;
