import React from 'react';

import { useQuery } from '@apollo/client';
import { Select } from '@chakra-ui/core';

import { loader } from 'graphql.macro';

const CATEGORIES_QUERY = loader('./list-categories.graphql');

const CategorySelector = ({onChange, selection, ...props}) => {
  const { loading, error, data } = useQuery(CATEGORIES_QUERY);

  let result = data;
  if (error) {
    console.log(error);
    result = { categories: [] };
  }

  return (
    <Select
      variant="filled"
      placeholder="Select category"
      value={selection ? selection : ''}
      onChange={onChange}
      icon={ loading ? "spinner" : (error ? "not-allowed": "chevron-down") }
    >
      {!loading && !error && result.categories.map(category => (
        <option
          key={category.id}
          value={category.id}
        >
          {category.name}
        </option>
      ))};
    </Select>
  );
};

export default CategorySelector;
