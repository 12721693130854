import React from 'react';

import { MdFilterList } from 'react-icons/md'

import {
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/core';


const FilterMenu = ({
  filter,
  onFilter,
  sorter,
  menuNamer,
  itemNamer,
  valueExtractor,
  data,
}) => {

  const sorted = [...data];
  sorted.sort(sorter);

  const FilterOptionList = ({options}) => {
    return options.map(o => {
      const key = valueExtractor(o);
      return (
        <MenuItemOption
          key={key}
          value={o}
          onClick={() => onFilter(key)}
        >
          {itemNamer(o)}
        </MenuItemOption>
      );
    });
  };

  return (
    <Menu w="30%">
      <MenuButton
        as={Button}
        fontSize={{sm: "0.8rem", md: "1rem"}}
        leftIcon={MdFilterList}
        rightIcon="chevron-down"
        maxW={{sm: "auto", md: "200px"}}
      >
        {menuNamer(sorted.find(s => `${valueExtractor(s)}` === `${filter}`))}
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          defaultValue={-1}
          type="radio"
          value={filter}
        >
          <MenuItemOption
            key="no_cat"
            value={-1}
            onClick={() => onFilter(null)}
          >
            {itemNamer(null)}
          </MenuItemOption>
          {data.length > 0 && (<MenuDivider />)}
          <FilterOptionList options={sorted} />
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};

export default FilterMenu;
