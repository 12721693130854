import React from 'react';

import { useParams } from 'react-router-dom';

import Game from '../components/Game/Game';

const GamePage = () => {
  const { gameId } = useParams();

  return <Game gameId={gameId}/>;
};

export default GamePage;
