import React from 'react';

import {
  Flex,
  Heading,
  Image,
  Stack,
} from '@chakra-ui/core';

const Footer = (props) => {

  return (
    <Flex
      as="footer"
      className="app-footer"
      align="center"
      justify="space-between"
      wrap="wrap"
      p={["0.5rem", "1rem"]}
      bg="white"
      color="black"
      maxW="100%"
      borderTopWidth="1px"
      borderTopColor="black"
      borderTopStyle="solid"
      {...props}
    >
      <Flex width="full" align="center" mr={5}>
        <Stack mx="auto" align="center" justify="center" textAlign="center">
          <Stack direction="row">
            <Image
              alignSelf="center"
              size="1.618rem"
              objectFit="contain"
              src="https://puthir.in/media/images/tsc-logo.svg"
              alt="The Solvers Club"
              mx={{sm: "0.1618rem", md: "0.618rem"}}
              mt="0rem"
            />
            <Heading
              as="h5"
              fontSize={{sm: "0.8rem", md: "1.1618rem", lg: "1.618rem"}}
              fontWeight="normal"
              lineHeight={{sm: "1rem", md: "1.618rem", lg: "2rem"}}
              pb={{sm: "0rem", md: "0.618rem"}}
              mt={{sm: "0.5rem", md: "0.618rem"}}
            >
              A learning experience by The Solvers Club
            </Heading>
          </Stack>
          <Heading
            fontWeight="normal"
            fontSize={{sm: "0.618rem", md: "1rem"}}
            lineHeight={{sm: "0.8rem", md: "1.618rem", lg: "2rem"}}
            as="p"
            pt={{sm: "0.1618rem", md: "0.618rem"}}
            mt={{sm: "-0.5rem", md: "-1.618rem"}}
          >
            Copyright &copy; 2020 Puthir Learning Pvt. Ltd.
          </Heading>
          <Image
            size="1.618rem"
            objectFit="contain"
            src="https://puthir.in/media/images/puthir-logo.svg"
            alt="Puthir"
            mx={{sm: "0.618rem", md: "1rem"}}
          />
        </Stack>
      </Flex>
    </Flex>
  );
};

export default Footer;
