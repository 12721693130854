import React from 'react';

import { useQuery } from '@apollo/client';
import { Select } from '@chakra-ui/core';

import { loader } from 'graphql.macro';

const USERS_QUERY = loader('./list-users.graphql');

const UserSelector = ({
  exclude,
  onChange,
  selection,
  ...props
}) => {
  const { loading, error, data } = useQuery(USERS_QUERY);

  const sorter = (u1, u2) => {
    if (u1.name < u2.name){
      return -1;
    }
    return 1;
  };

  const filterOut = exclude ? new Set(exclude) : new Set([]);

  let result = data;
  if (loading || error) {
    result = { users: [] };
  }

  const sorted = [...result.users];
  sorted.sort(sorter);

  const selectionChanged = (e) => {
    const userId = e.target.value;
    const user = result.users.find(u => u.source_id === userId);
    onChange(user);
  };

  return (
    <Select
      variant="filled"
      placeholder="Select user"
      value={selection ? selection.source_id : ''}
      onChange={selectionChanged}
      icon={ loading ? "spinner" : (error ? "not-allowed": "chevron-down") }
    >
      {!loading &&
       !error &&
       sorted.filter(u => !filterOut.has(u.source_id))
             .map(user => (
        <option
          key={user.source_id}
          value={user.source_id}
        >
          {user.name}
        </option>
      ))};
    </Select>
  );
};

export default UserSelector;
