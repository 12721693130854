import React from 'react';

import {
  Heading,
  Stack,
} from '@chakra-ui/core';


import { useQuery } from '@apollo/client';

import { loader } from 'graphql.macro';

import GamePageHeader from './GamePageHeader';
import TeamList from './Team/TeamList';

const GET_GAME_QUERY = loader('./get-game.graphql');

const Game = ({gameId}) => {

  const { loading, error, data } = useQuery(
    GET_GAME_QUERY,
    {
      variables: {
        gameId,
      }
    }
  );

  let result = data;
  if (loading || error) {
    result = { game: null };
  }

  const { games_by_pk: game } = result;

  if (!game) {
    return <></>;
  }


  return (
    <Stack spacing={32}>
      <GamePageHeader
        game={game}
      />
      <Stack spacing={8} my="1rem" px="1rem">
        <Heading as="h4" fontSize="1.5rem">
          Teams
        </Heading>
        <TeamList teams={game.teams} game={game} />
      </Stack>
    </Stack>
  );
};

export default Game;
