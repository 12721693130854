import React from 'react';


import { useQuery } from '@apollo/client';

import { loader } from 'graphql.macro';

import DashboardScore from './Score';

const DASH_FAIRNESS_QUERY = loader('./dash-fairness.graphql');


const scorer = (u) => u.scores_aggregate.aggregate.sum.fairness;

const DashboardFairness = () => {
  const { loading, error, data } = useQuery(DASH_FAIRNESS_QUERY);

  let result = data;
  if (loading || error) {
    result = { users: [] };
  }

  return <DashboardScore data={result.users} scorer={scorer}/>;
};

export default DashboardFairness;
