import React, { useState, useCallback } from 'react'

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/core';


import Cropper from 'react-easy-crop';

import getCroppedImage from '../../utils/crop';


import SlidingNumber from '../SlidingNumber/SlidingNumber';

const ImageSelect = ({
  img,
  setCroppedImage,
  isOpen,
  onOpen,
  onClose,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImage(
        img,
        croppedAreaPixels,
        rotation
      )
      setCroppedImage(croppedImage)
      onClose();
    } catch (e) {
      console.error(e)
    }
  }, [img, onClose, setCroppedImage, croppedAreaPixels, rotation]);

  const onCancel = useCallback(() => {
    setCroppedImage(null)
    onClose();
  }, [setCroppedImage, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Resize Image</ModalHeader>
        <ModalBody>
          <Box
            w="100%"
            h="sm"
            position="relative"
          >
            <Cropper
              image={img}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </Box>
          <Stack spacing={1} w="100%">
            <Box w="100%" shadow="md" borderWidth="1px" padding="1rem">
              <FormControl>
                <FormLabel htmlFor="zoom-slider">
                  Zoom
                </FormLabel>
                <SlidingNumber
                  defaultValue={1}
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  onChange={z => setZoom(z)}
                  sliderProps={{
                    id:"zoom-slider",
                    name:"zoom-slider",
                    "aria-labelledby":"Zoom"
                  }}
                  numberProps={{
                    id:"zoom-stepper",
                    name:"zoom-stepper",
                    "aria-labelledby":"Zoom"
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="rot-slider">
                  Rotation
                </FormLabel>
                <SlidingNumber
                  defaultValue={0}
                  value={rotation}
                  min={0}
                  max={360}
                  step={1}
                  sliderProps={{
                    id:"rot-slider",
                    name:"rot-slider",
                    "aria-labelledby":"Rotation"
                  }}
                  numberProps={{
                    id:"rot-stepper",
                    name:"rot-stepper",
                    "aria-labelledby":"Rotation"
                  }}
                  onChange={r => setRotation(r)}
                />
              </FormControl>
            </Box>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button variantColor="blue" mr={3} onClick={showCroppedImage}>
            Finish
          </Button>
          <Button
            onClick={onCancel}
            variant="ghost">
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ImageSelect;
