import React from 'react';

import { MdPageview } from 'react-icons/md'

import {
  Box,
  Button,
  Divider,
  Grid,
  Text,
} from '@chakra-ui/core';

import { Link as RRLink } from 'react-router-dom';

import { smartStartDate, smartEndDate } from '../../utils/date';

const Games = ({games}) => {
  return (
    games.map((game, i) => (
      <Box
        pt={i === 0 ? "0rem" : "1rem"}
        pb="0.5rem"
        key={game.id}
      >
        {i > 0 && (<Divider mb="0rem" mt="-1rem" height="1px" />)}
        <Grid
          px={{sm: "0.5rem", md: "1rem"}}
          pt="1rem"
          templateColumns="160px 160px 96px" gap={1}
        >
          <Text>
            {smartStartDate(game.start_date)}
          </Text>
          <Text>
            {smartEndDate(game.end_date, 'No end date')}
          </Text>
          <Button
            height="2rem"
            mt="-0.3rem"
            variant="outline"
            leftIcon={MdPageview}
            as={RRLink}
            to={`/games/${game.id}`}
          >
            View
          </Button>
        </Grid>
      </Box>
    ))
  );
};

export default Games;
