import React, { useState } from 'react';

import { MdClear, MdSelectAll } from 'react-icons/md'

import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Stack,
  SimpleGrid,
} from '@chakra-ui/core';


const CheckBoxList = ({
  objects,
  keyGetter,
  textGetter,
  onChange,
  ActionButton,
}) => {
  const [selection, setSelection] = useState(new Set(objects.map(keyGetter)));

  const onCheckedChange = (e) => {
    const {checked, value} = e.target;
    if (checked) {
      selection.add(value);
    } else {
      selection.delete(value);
    }
    const selectedObjects = objects.filter(o => selection.has(keyGetter(o)));
    onChange(selectedObjects);
    setSelection(new Set(selection));
  }

  const selectAll = () => {
    setSelection(new Set(objects.map(keyGetter)));
  }

  const unselectAll = () => {
    setSelection(new Set([]));
  }

  const Action = () => ActionButton ? <ActionButton /> : <Box/>;

  return (
    <Stack>
      <Grid
        templateColumns="auto 32px 32px"
        gap={3}
        mr="1rem"
      >
        <Action />
        <IconButton icon={MdSelectAll} onClick={selectAll} />
        <IconButton icon={MdClear} onClick={unselectAll} />
      </Grid>
      <SimpleGrid
        my="1rem"
        columns={3}
        spacing={4}
      >
        {objects.map(o => (
          <Checkbox
            key={keyGetter(o)}
            isChecked={selection.has(keyGetter(o))}
            onChange={onCheckedChange}
            value={keyGetter(o)}
          >
            {textGetter(o)}
          </Checkbox>
        ))}
      </SimpleGrid>
    </Stack>
  );

};

export default CheckBoxList;
