import React from 'react';

import {
  Box,
  Stack,
} from '@chakra-ui/core';


const UserList = ({users}) => {

  const sorter = (u1, u2) => {
    if (u1.name < u2.name){
      return -1;
    }
    return 1;
  };

  const sorted = users ? [...users] : [];
  sorted.sort(sorter);
  return (
    <Stack>
      {sorted.map(user => (
        <Box m="0" p="1rem" borderWidth="1px" key={user.source_id}>
          {user.name}
        </Box>
      ))};
    </Stack>
  );
};

export default UserList;
